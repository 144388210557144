<template>
	<div class="taskWrap">
		<div class="titleWrap">
			<div>
				<ul class="taskTab">
					<li
						v-for="(item, index) in taskTab"
						:key="index"
						:class="nowActive == item.id ? 'on' : ''"
						@click="changeTab(item)"
					>
						{{ item.name }}
					</li>
				</ul>
			</div>
		</div>
		<div class="noDataWrap" v-if="taskList.length == 0">
			<img src="@/assets/images/noData.png" alt="" />
			<p v-if="nowActive == 4">暂无已获得证书</p>
			<p v-if="nowActive == 0">暂无待申请证书</p>
			<p v-if="nowActive == 3">暂无已驳回证书</p>
            <p v-if="nowActive == 1">暂无审核中证书</p>
		</div>
		<div v-else>
			<div class="certificateWrap">
                <dl v-for="(item,index) in taskList" :key="item.id">
                    <dt>
                        <img :src="item.resource_image" alt="">
                    </dt>
                    <dd>
                        <p>{{ item.resource_title }}</p>
                        <span v-if="item.status==4" @click="isState(item.resource_id,item.certificate_id,item.resource_title)">查看证书</span>
                        <span v-if="item.status==0" @click="isState(item.resource_id,item.certificate_id,item.resource_title)">申请证书</span>
                        <span v-if="item.status==3" @click="isState(item.resource_id,item.certificate_id,item.resource_title)">重新申请</span>
                        <span v-if="item.status==1 || item.status==2" @click="isState(item.resource_id,item.certificate_id,item.resource_title)">查看证书</span>
                    </dd>
                </dl>
            </div>
            <div class="order_pages">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[20]" :page-size="limit"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
		</div>
        <!-- 证书状态 -->
        <CertificateStatus ref="CertificateStatus"></CertificateStatus>
        <applyState ref="applyState" @tipsShow="tipsShow"></applyState>
        <informationPrompt ref="informationPrompt" @determine="determine"></informationPrompt>
	</div>
</template>

<script>
import { certificateList } from '../assets/api';
import { certificateStatus } from '@p/learn/assets/api'
import ViewCertificate from './com/view_certificate.vue';
// import ApplyState from './com/apply_state.vue';
import applyState from "@p/learn/components/applyState.vue";
import CertificateStatus from "@p/learn/components/certificate_status.vue";
import informationPrompt from "@p/learn/components/informationPrompt.vue";
export default {
	components: {
        CertificateStatus,
        applyState,
        informationPrompt
	},
	data() {
		return {
			taskList: [],
			page: 1,
			limit: 20,
			taskTab: [
				{
					id: 4,
					name: '已获得',
				},
				{
					id: 0,
					name: '待申请',
				},
				{
					id: 3,
					name: '已驳回',
				},
                {
					id: 1,
					name: '审核中',
				},
			],
			nowActive: 4,
            currentPage: 1,
            total:0
		};
	},
	mounted() {
		this.getList();
	},
	methods: {
        // 消息提示框
        tipsShow (e) {
            this.$refs.informationPrompt.openInit(e)
        },
        // 消息框确定
        determine (e) {
            this.$refs.applyState.doSubmit(e)
            this.getList();
        },
        // 判断证书状态
        isState (course_id, certificate_id,course_title) {
            let params = {
                course_id,
                certificate_id
            }
            certificateStatus(params).then(res => {
                console.log(res,11000000000);
                if (res.code == 0) {
                    let status = res.data.cert_test.status
                    let certificate_type = res.data.cert_test.certificate_type
                    if (certificate_type == 2) {
                        this.$refs.CertificateStatus.openInit(certificate_type)
                    } else {

                        if (status == 10 || status == 9 || status == 8 || status == 7) {
                            this.$refs.CertificateStatus.openInit(certificate_type, status)
                        } else if (status == 5 || status == 6) {
                            this.$refs.CertificateStatus.openInit(certificate_type, status, res.data.cert_test.copywriting)
                        } else {
                            this.$refs.applyState.openInit(res.data.cert_test, course_title)
                        }
                    }

                }
            })
        },
        certificateState(){
            this.$refs.ApplyState.openInit()
        },
        ViewCertificate(){
            this.$refs.ViewCertificate.openInit()
        },
		getList() {
			const params = {
				page: this.page,
				status: this.nowActive,
			};
			certificateList(params).then((res) => {
                console.log(res);
				if (res.code == 0) {
                    this.taskList = res.data.list;
                    this.total = res.data.total
				}
			});
		},
		changeTab(item) {
			this.nowActive = item.id;
			this.taskList = [];
			this.limit = 20;
			this.page = 1;
			this.getList(1);
		},
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`);
        }
	},
};
</script>

<style lang="scss" scoped>
.certificateWrap{
    margin:32px 0 0 0;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    dl{
        width:252px;
        height:318px;
        background-color:#fff;
        margin:0 23px 24px 0;
        dt{
            width:252px;
            height:190px;
            background-color:#EEEEEE;
            border-radius:6px 6px 0 0;
            display: flex;
            align-items: center;
            img{
                width:100%;
            }
        }
        dd{
            width:252px;
            height:128px;
            background-color:#fff;
            box-shadow: 0 0 5px 2px #eee;
            border-radius: 0 0 6px 6px;
            text-align: center;
            p{
                font-size: 16px;
                line-height: 24px;
                height: 50px;
                color:#333;
                text-align: left;
                padding-top:12px;
                margin:0 20px 8px 20px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                /* 限制显示的行数 */
                overflow: hidden;
            }
            span{
                display: inline-block;
                width:108px;
                height:32px;
                border:1px solid var(--change-color);
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                font-size: 14px;
                color:var(--change-color);
                cursor: pointer;
                &:hover{
                    color:#fff;
                    background:var(--change-color);
                }
            }
        }
    }
    dl:nth-of-type(4n){
        margin:0 0 24px 0;
    } 
    .noMargin{
        margin: 0 0 24px 0;
    }
}
.order_pages {
        display: flex;
        justify-content: end;
    }
.taskWrap {
	padding: 20px 30px;
	box-sizing: border-box;
	.noDataWrap {
		text-align: center;
		margin: 100px 113px;
		p {
			margin: 25px 0;
			font-size: 16px;
			color: #999999;
		}
	}
	.titleWrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.taskTab {
			display: flex;
			li {
				margin-right: 40px;
				font-size: 16px;
				cursor: pointer;
				position: relative;
				&.on {
					color: var(--change-color);
					font-weight: bold;
				}
				&.on::after {
					clear: both;
					content: '';
					position: absolute;
					left: 50%;
					bottom: -9px;
					width: 24px;
					height: 3px;
					margin-left: -12px;
					background: var(--change-color);
					border-radius: 1.5px;
				}
			}
		}

		i {
			font-size: 16px;
		}
		.el-button.is-round {
			//padding: 10px 20px;
      width: 118px;
      height: 36px;
      padding:10px 23px
		}
    .bluePlainBtn{
      background:#fff;
      border:1px solid var(--change-color);
      color:var(--change-color);
      i{}
      &:hover{
        background:var(--change-color);
        color:#fff
      }
    }
	}

}
.moreBtn {
	margin: 0 auto;
	margin-top: 30px;
	width: 100px;
	height: 30px;
	font-size: 14px;
	border-radius: 18px;
	border: 1px solid #e1e1e1;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
	color: #666;
	&:hover {
		background: var(--change-color);
		border: 1px solid var(--change-color);
		color: #fff;
	}
	i {
		font-size: 14px;
	}
}
</style>